/* Custom Scrollbar Styles for WebKit Browsers */
.scroll-container {
    overflow-x: auto; 
}

/* WebKit Scrollbar */
.scroll-container::-webkit-scrollbar {
    width: 12px; 
}

.scroll-container::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

.scroll-container::-webkit-scrollbar-thumb {
    background: rgb(79, 76, 207); 
    border-radius: 6px; 
}

.scroll-container::-webkit-scrollbar-thumb:hover {
    background: rgb(79, 76, 207); }

/* Custom Scrollbar Styles for Firefox */
.scroll-container {
    scrollbar-width: thin; 
    scrollbar-color:rgb(79, 76, 207) #f1f1f1;
}
