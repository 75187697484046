@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

*{
    box-sizing: border-box;
    margin: 0%;
    padding: 0%;
}
#heading{
    color: white;
    /* font-size: 7vmax; */
    font-family: 'Roboto';
    letter-spacing: 10px;
    font-weight: 500;
}
#heading>p{
     transition: all .3s;
 }
#heading>p:hover{
   cursor: default;
    transform: translateY(-50px);
}



#black::after{
    content: " ";
    width: 100%;
    height: 60vh;
    background-color: black;
    opacity: 0.8;
    backdrop-filter: blur(5px);
    position: absolute;
    top: 0;
}

::selection{
    color: #ec70b0;
}
#scalItemOnHover{
    transition:all .5s
}
#scalItemOnHover:hover{
    transform: translateY(-20px);
}/* Basic Pagination Styling */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  list-style: none;
  background-color: #f8f9fa;
}

.page-item {
  margin: 0 5px;
}

/* Styling for Page Links */
.page-link {
  display: block;
  padding: 8px 12px;
 
  text-decoration: none;
  border: 1px solid #360227;  /* Light border */
  border-radius: 4px;
  transition: background-color 0.3s;
}

/* Hover Effect */
.page-link:hover {
  background: #7928CA !important;
  color: #fff;  /* White text on hover */
}

/* Active Page Styling */
.active .page-link {
  background: #7928CA !important;
  color: white;
  font-weight: bold;
}

/* Previous & Next Button Styling */
.page-item .page-link {
  background-color: #e9ecef;
  color: #65187a;
}

.page-item .page-link:hover {
  background: purple;
  color: #fff;
}

/* Disabled State (when there are no more pages) */
.page-item.disabled .page-link {
  background-color: #f1f1f1;
  color: #ccc;
  pointer-events: none;
}


.input-field:not(:placeholder-shown) {
  color: black;  /* Change text color when input has content */
}


/* Change background color after option is selected */
/* select:placeholder-shown {
  color: purple !important; 
}
select:not(:placeholder-shown) {
  color: black;
} */
.profile{
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height:1.3;
}
