.invoicePayment {
    width: 100%;
}

.invoice-bgpic {
    width: 100%;
}

.invoice-bgpic>img {
    width: 100%;
    height: 5vh;
}

.invoice-head {
    width: 100%;
    /* margin: auto; */
    /* padding: 1rem; */
    display: flex;
    justify-content: space-between;
}

.invoice-head-logo {
    width: 40%;
}

.invoice-head-logo>h4 {
    font-size: 1.3rem;
    font-weight: 600;
    font-family: sans-serif;
}

.invoice-head-box {
    width: 40%;
    justify-content: flex-end;
}

.invoice-head-box>h2 {
    display: flex;
    justify-content: flex-end;
    font-size: 2.5rem;
    font-weight: 600;
    font-family: sans-serif;
}

.invoice-box-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.invoice-no {
    
}

.invoice-no>li {
    font-size: 0.9rem;
    font-weight: 500;
    color: rgb(80, 77, 77);
}

.invoice-date {
   
}

.invoice-date>li {
    font-size: 0.9rem;
    font-weight: 500;
    color: rgb(80, 77, 77);
    margin-left: 0.8rem;
    display: flex;
    justify-content: flex-end;
}

.invoice-to {
    width: 100%;
    /* margin: auto; */
    /* padding: 1rem; */
}


.invoice-bottom {
    display: flex;
    /* margin-top: 1rem; */
}

.invoice-details>li {
    font-size: 0.9rem;
    font-weight: 500;
    color: rgb(80, 77, 77);
    margin-right: 2rem;
}

.invoice-bill-table {
    width: 100%;

}
input:focus{
    outline: none;
}

.invoice-condition-para {
    width: 100%;
    /* margin: auto; */
    /* padding: 1rem; */
    display: flex;
    justify-content: space-between;
}

.term-condition {
    width: 30%;
}

.term-condition>h3 {
    font-size: 1.2rem;
    font-weight: 700;
}

.term-condition>p {
    font-size: 0.9rem;
    color: rgb(80, 77, 77);
}

.invoice-amount {
    width: 50%;
    display: flex;
    justify-content: flex-end;
}



.amount-des>p {
    font-size: 0.9rem;
    font-weight: 700;
}

.amount-price>p {
    display: flex;
    justify-content: flex-end;
    font-size: 0.9rem;
    font-weight: 500;
    color: rgb(80, 77, 77);
    margin-left: 2rem;
}

.thank-invoice-section {
    width: 100%;
    /* margin: auto; */
    /* padding: 1rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.invoice-contact>h3 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
}

.social-items {
    display: flex;
}

.social-icons>li>a>img {
    width: 20px;
    height: 20px;
    margin: 5px;
}

.social-text>li {
    font-size: 0.9rem;
    font-weight: 500;
    color: rgb(80, 77, 77);
    margin-left: 0.7rem;
    line-height: 2;
}

.right-contact {
    display: block;
    align-items: center;
    padding-left: 1rem;
}

.upperline {
    border-top: 1px solid rgb(158, 146, 146);
    margin-bottom: 0.5rem;
}

.right-contact>h2 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
}

.right-contact>p {
    text-align: center;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: rgb(80, 77, 77);
}

