.loader{
    font-size: 48px;
    /* color: #FFF; */
    background-image:'linear(to-l,#FF0080, #7928CA)';
    display: inline-block;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    position: relative;
    
  }
  .loader:before{
    content: '';
    animation: 2s print linear alternate infinite;
  }
  .loader:after{
    content: '';
    position: absolute;
    right: -4px;
    top: 50%;
    transform: translatey(-45%);
    width: 2px;
    height: 1.3em;
    background: currentColor;
    opacity: 0.8;
    animation: .2s blink steps(2) infinite;
  }
  
  @keyframes blink {
    0%  { visibility: hidden;}
    100%  { visibility: visible;}
  }
  @keyframes print {
    0% { content: 'Qu'}
    10% { content: 'Qui'}
    20% { content: 'Quic'}
    30% { content: 'Quick'}
    40% { content: 'QuickB'}
    50% { content: 'QuickBi'}
    60% { content: 'QuickBil'}
    70% { content: 'QuickBill.'}
    80% { content: 'QuickBill.A'}
    90% , 100% { content: 'QuickBill.Ai'}
  }