.gigSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gigYellowClr {
    width: 50%;
    display: flex;
    align-items: center;
}

.bgyellow {
    width: 3%;
    background: #FF8E00;
    height: 12vh;
}

.gigCaption {
    margin-left: 1rem;
}

.gigCaption>h3 {
    font-size: 1.5rem;
    text-transform: capitalize;
    font-weight: 700;
}

.gigCaption>li{
    font-size: 0.9rem;
    text-transform: capitalize;
    font-weight: 500;
    color: grey;
}

.yellowInvoice{
    font-size: 1.8rem;
    text-transform:uppercase;
    font-weight: 800;
    background: #FF8E00;
    padding:0.5rem 2rem;
    border-radius: 30px 0 0 30px;
}
.billTo{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:space-between;
}
.billyellow,
.yellowinvoicno{
}
.billyellow>h3{
    font-size: 1.4rem;
    font-weight: 750;
    text-transform: uppercase;
}
.billyellow>li{
    font-size: 1.2rem;
    text-transform: capitalize;
    font-weight: 700;
    color:black;
}

.yellowinvoicno{
    display: flex;
}
.yellowList1{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.yellowlist2{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.yellowList1>li,
.yellowlist2>li{
    font-size:1.2rem;
    text-transform: capitalize;
    font-weight: 600;
    color:grey;
}

.bgyellowTable{
    width: 100%;
}
.yellowTotal{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:space-between;
}
.yellowSub>li,
.yelloamount>li{
    font-size:1.5rem;
    text-transform: capitalize;
    font-weight: 600;
    color:black;
}
.yelloamount{
    margin-right: 4rem;
}

.yellowsubTotal{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:space-between;
    border-top: 3px solid black;
    /* border: 1px solid red; */
}
.yellowsubTotal>p{
    margin-right: 4rem;
    font-size:1.5rem;
    text-transform: capitalize;
    font-weight: 600;
    color:black;
}

.paymentMethod{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:space-between;
}

.paymentMethod>p>h3{
    font-size: 1.4rem;
    font-weight: 750;
    text-transform: capitalize;
}
.paymentMethod>p>li{
    font-size:1.3rem;
    text-transform: capitalize;
    font-weight: 600;
    color:black;
}

.paymentMethod>p>h5{
    font-size: 1.4rem;
    font-weight: 750;
    text-transform: capitalize;
}
.paymentMethod>p>small{
    font-size:1.2rem;
    text-transform: capitalize;
    font-weight: 600;
    color:gray;
}
li{
    list-style: none;
}
