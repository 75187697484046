.scrollBar {
    scrollbar-width: thin;
    scrollbar-color: pink;
}

.scrollBar::-webkit-scrollbar {
    width: 1px;
}

.scrollBar::-webkit-scrollbar-track {
    background: red !important;
}

.scrollBar::-webkit-scrollbar-thumb {
    background: blue;
    border-radius: 10px;
    border: 1px solid white;
}


.logoutWrapper {
    width: 100%;
    padding: 15px 0px;
    margin-top: 10rem;
}

.logoutWrapper .logoutBox {
    width: 100%;
    height: 150px;
    padding: 25px;
    background: #f0bcfd;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.logoutWrapper .logoutBox::before {
    content: '';
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: #db08f7;
    position: absolute;
    top: -20px;
    right: -20px;
    opacity: 0.5;

}

.logoutWrapper .logoutBox::after {
    content: '';
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #db08f7;
    position: absolute;
    top: 60%;
    left: 1px;
    opacity: 0.4;

}

.logoutWrapper .logoutBox>Button {
    width: 100%;
    margin: 30px;
    border-radius: 8px;
    font-size: 1.2rem;
    font-weight: 700;
}
@media screen and (max-width:1500px) {
    .logoutWrapper {
        margin-top: 3rem;
    }
}
@media screen and (max-width:800px) {
    .logoutWrapper {
        margin-top: 1rem;
    }
}

