
.payBill-section{
    width: 100%;
    margin: auto;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    /* border: 1px solid red; */
    padding: 3rem 0;
}
.payData{
    width: 60%;
    /* border: 1px solid red; */
}
.bgGreenpic{
    width: 20%;
    /* position: relative; */
}
.payData>h3{
    font-size:1.2rem ;
    font-weight: 800;
    text-transform: uppercase;
    color: rgb(38, 68, 80);
}
.payData>li{
    font-size: 0.9rem;
    color: rgb(72, 119, 138);
}
.bgGreenpic{
    background: url(../../../img/invoice-img/skybluepic.jpeg) no-repeat;
    background-size: cover;
    background-position: bottom; 
    width: 400px; 
    height: 200px;
    display: flex;
    align-items:center; 
    justify-content: center;
    /* position: absolute;
    left: 62%; */
}
.bggreenText{
}

.bggreenText>li{
    font-size: 0.9rem;
    font-weight: 600;
    color: rgb(72, 119, 138);
    text-transform: capitalize;
}
.greenTable{
    width: 100%;
    margin: auto;
}
.notes{
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
}
.notesOne{
    width: 40%;
}

.notesOne>h3{
    font-size:1.2rem ;
    font-weight: 800;
    text-transform: uppercase;
    color: rgb(38, 68, 80);
}
.notesOne>p{
    font-size: 0.9rem;
    color: rgb(72, 119, 138);
}
.notesTwo>p{
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
    color: rgb(38, 68, 80);
}
.notesThree>p{
    font-size: 1rem;
    color: rgb(38, 68, 80);
}

.temp-foot-clr{
    background: #0c9597;
    color: white;
    padding: 1rem 0;
}

.TEMP-FOOT{
   width: 100%;
   margin: auto;
   /* padding: 2rem 0; */
   display: flex;
   align-items: center;
   justify-content: space-between;
   background-color: #033435;
   border-radius:30px ;
   padding: 1rem 1rem 0 ;
}
.topheader{
    width: 100%;
    /* margin: auto; */
    display: flex;
   align-items: center;
   justify-content: space-between;

}

.topheaderlogo{
    display: flex;
    width:20%;
}
.topheaderlogo>img{
    width:100%;
}
.headName{
    padding: 1rem;
}
.headName>h3{
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    font-family: sans-serif;
}
.headName>span{
    font-size:0.8rem;
    font-weight: 500;
    text-transform:capitalize;
    font-family: sans-serif;
    color: rgb(38, 68, 80);
}
.topheader>small{
    font-size: 2.5rem;
    font-weight: 800;
    text-transform: uppercase;
    font-family:monospace;
}