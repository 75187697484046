header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* margin-top: 18vmax; */
}

.left-head,
.right-head {
}

header > .left-head > p {
  font-size: 1.5rem;
  font-weight: 700;
  font-family: sans-serif;
  width: 20%;
}

header > .right-head {
  font-size: 2.5rem;
  font-weight: 800;
  font-family: sans-serif;
}

#invoceTo {
  width: 100%;
  margin: 1.5vmax 0;
  padding: 1rem 0;
}

#invoceTo > li {
  font-size: 1rem;
  font-weight: 600;
  font-family: sans-serif;
  color: rgb(6, 6, 83);
  text-transform: capitalize;
  margin: 0.5vmax 0;
}

section {
  width: 100%;
  margin: 1.5vmax 0;
  display: flex;
  align-self: center;
  justify-content: space-between;
}

.section-left,
.section-right {
}

section > .section-left > li {
  font-size: 1rem;
  font-weight: 600;
  font-family: sans-serif;
  color: rgb(6, 6, 83);
  text-transform: capitalize;
  margin: 0.5vmax 0;
}

section > .section-right > li {
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  font-family: sans-serif;
  color: rgb(6, 6, 83);
  text-transform: capitalize;
  margin: 0.5vmax 0;
}

.table-list {
  width: 100%;
  margin: 1.5vmax 0;
}

#tr-bg th {
  background-image: url('../../../img/invoice-img/templatebg.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  padding: 0.7rem;
}
td {
  color: rgb(6, 6, 83);
}

/* #skyblue{
    background-color: #a1e4f1;
} */

#invoiceTerm {
  width: 100%;
  margin: 1.5vmax 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#term-right,
#term-left {
  width: 40%;
}

#term-left {
  margin-top: 3rem;
}
#term-left > p {
  font-size: 0.8rem;
  font-weight: 600;
  font-family: sans-serif;
  color: rgb(6, 6, 83);
  text-transform: capitalize; 
}
#term-left > h3 {
  font-size: 0.8rem;
  font-weight: 800;
  font-family: sans-serif;
  color: rgb(6, 6, 83);
  text-transform: capitalize;
}

#term-right {
  /* display: flex;
  justify-content: space-between; */
}

#subAmount > p {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
#amount-cal {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1rem;
  font-weight: 800;
  font-family: sans-serif;
  color: rgb(6, 6, 83);
  text-transform: capitalize;
}

.bottom-head {
  width: 100%;
  margin: 1.5vmax 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottom-left,
.bottom-right {
  width: 25%;
}

.bottom-left > li {
  font-size: 0.9rem;
  font-weight: 600;
  font-family: sans-serif;
  color: rgb(6, 6, 83);
  text-transform: capitalize;
  margin: 0.5vmax 0;
}
li {
  margin: 0;
}
.underline2 {
  border: 1px solid rgb(6, 6, 83);
}

.bottom-right > h3 {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.bottom-right {
  padding-top: 2rem;
  text-align: center;
  text-transform: capitalize;
  color: rgb(6, 6, 83);
  font-weight: 600;
}
